@charset "UTF-8";
/*
ユーティリティ系おまとめファイル
*/
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
.u-pt0 {
  padding-top: 0px; }

.u-pb0 {
  padding-bottom: 0px; }

.u-mt0 {
  margin-top: 0px; }

.u-mb0 {
  margin-bottom: 0px; }

.u-pt5 {
  padding-top: 5px; }

.u-pb5 {
  padding-bottom: 5px; }

.u-mt5 {
  margin-top: 5px; }

.u-mb5 {
  margin-bottom: 5px; }

.u-pt10 {
  padding-top: 10px; }

.u-pb10 {
  padding-bottom: 10px; }

.u-mt10 {
  margin-top: 10px; }

.u-mb10 {
  margin-bottom: 10px; }

.u-pt15 {
  padding-top: 15px; }

.u-pb15 {
  padding-bottom: 15px; }

.u-mt15 {
  margin-top: 15px; }

.u-mb15 {
  margin-bottom: 15px; }

.u-pt20 {
  padding-top: 20px; }

.u-pb20 {
  padding-bottom: 20px; }

.u-mt20 {
  margin-top: 20px; }

.u-mb20 {
  margin-bottom: 20px; }

.u-pt25 {
  padding-top: 25px; }

.u-pb25 {
  padding-bottom: 25px; }

.u-mt25 {
  margin-top: 25px; }

.u-mb25 {
  margin-bottom: 25px; }

.u-pt30 {
  padding-top: 30px; }

.u-pb30 {
  padding-bottom: 30px; }

.u-mt30 {
  margin-top: 30px; }

.u-mb30 {
  margin-bottom: 30px; }

.u-pt35 {
  padding-top: 35px; }

.u-pb35 {
  padding-bottom: 35px; }

.u-mt35 {
  margin-top: 35px; }

.u-mb35 {
  margin-bottom: 35px; }

.u-pt40 {
  padding-top: 40px; }

.u-pb40 {
  padding-bottom: 40px; }

.u-mt40 {
  margin-top: 40px; }

.u-mb40 {
  margin-bottom: 40px; }

.u-pt45 {
  padding-top: 45px; }

.u-pb45 {
  padding-bottom: 45px; }

.u-mt45 {
  margin-top: 45px; }

.u-mb45 {
  margin-bottom: 45px; }

.u-pt50 {
  padding-top: 50px; }

.u-pb50 {
  padding-bottom: 50px; }

.u-mt50 {
  margin-top: 50px; }

.u-mb50 {
  margin-bottom: 50px; }

.u-pt55 {
  padding-top: 55px; }

.u-pb55 {
  padding-bottom: 55px; }

.u-mt55 {
  margin-top: 55px; }

.u-mb55 {
  margin-bottom: 55px; }

.u-pt60 {
  padding-top: 60px; }

.u-pb60 {
  padding-bottom: 60px; }

.u-mt60 {
  margin-top: 60px; }

.u-mb60 {
  margin-bottom: 60px; }

.u-pt65 {
  padding-top: 65px; }

.u-pb65 {
  padding-bottom: 65px; }

.u-mt65 {
  margin-top: 65px; }

.u-mb65 {
  margin-bottom: 65px; }

.u-pt70 {
  padding-top: 70px; }

.u-pb70 {
  padding-bottom: 70px; }

.u-mt70 {
  margin-top: 70px; }

.u-mb70 {
  margin-bottom: 70px; }

.u-pt75 {
  padding-top: 75px; }

.u-pb75 {
  padding-bottom: 75px; }

.u-mt75 {
  margin-top: 75px; }

.u-mb75 {
  margin-bottom: 75px; }

.u-pt80 {
  padding-top: 80px; }

.u-pb80 {
  padding-bottom: 80px; }

.u-mt80 {
  margin-top: 80px; }

.u-mb80 {
  margin-bottom: 80px; }

.u-pt85 {
  padding-top: 85px; }

.u-pb85 {
  padding-bottom: 85px; }

.u-mt85 {
  margin-top: 85px; }

.u-mb85 {
  margin-bottom: 85px; }

.u-pt90 {
  padding-top: 90px; }

.u-pb90 {
  padding-bottom: 90px; }

.u-mt90 {
  margin-top: 90px; }

.u-mb90 {
  margin-bottom: 90px; }

.u-pt95 {
  padding-top: 95px; }

.u-pb95 {
  padding-bottom: 95px; }

.u-mt95 {
  margin-top: 95px; }

.u-mb95 {
  margin-bottom: 95px; }

.u-pt100 {
  padding-top: 100px; }

.u-pb100 {
  padding-bottom: 100px; }

.u-mt100 {
  margin-top: 100px; }

.u-mb100 {
  margin-bottom: 100px; }

.slick-prev, .slick-next {
  width: 49px;
  height: 49px;
  background-color: #43B7FA;
  z-index: 2; }
  .slick-prev:focus, .slick-next:focus {
    background-color: #43B7FA; }
  .slick-prev:hover, .slick-next:hover {
    background-color: #43B7FA; }
  .slick-prev:before, .slick-next:before {
    content: '';
    font-size: 26px;
    color: #FFFFFF;
    opacity: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.slick-prev {
  left: 7px; }
  .slick-prev:before {
    content: "\f060";
    font-family: "Font Awesome 5 Free";
    font-weight: 600; }

.slick-next {
  right: 7px; }
  .slick-next:before {
    content: "\f061";
    font-family: "Font Awesome 5 Free";
    font-weight: 600; }

.slick-dots {
  bottom: -40px; }
  .slick-dots li button:before {
    font-size: 12px;
    color: #43B7FA !important; }

#top.is-animate .p-mainVisual__img {
  animation-name: bgImage;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards; }

#top.is-animate .p-mainVisual__img--left:before, #top.is-animate .p-mainVisual__img--right:before {
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-delay: 1s; }
  @media screen and (max-width: 768px) {
    #top.is-animate .p-mainVisual__img--left:before, #top.is-animate .p-mainVisual__img--right:before {
      animation-duration: .8s; } }
  #top.is-animate .p-mainVisual__img--left:before {
    animation-name: door-left; }
  #top.is-animate .p-mainVisual__img--right:before {
    animation-name: door-right; }

#top.is-animate .l-header {
  animation-name: headerDown;
  animation-duration: .5s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-delay: 2.8s; }

@keyframes bgImage {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes door-left {
  0% {
    left: -100%;
    opacity: 0; }
  30% {
    opacity: 1; }
  100% {
    left: 0%;
    opacity: 1; } }

@keyframes door-right {
  0% {
    right: -100%;
    opacity: 0; }
  30% {
    opacity: 1; }
  100% {
    right: 0%;
    opacity: 1; } }

@keyframes headerDown {
  0% {
    opacity: 0;
    top: -80px; }
  80% {
    opacity: 1; }
  100% {
    opacity: 1;
    top: 0; } }

@keyframes headerDown-sp {
  0% {
    opacity: 0;
    top: -70px; }
  80% {
    opacity: 1; }
  100% {
    opacity: 1;
    top: 0; } }

.c-worksList__list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -60px; }
  @media screen and (max-width: 700px) {
    .c-worksList__list {
      margin-bottom: -50px;
      flex-direction: column; } }
  .c-worksList__list .c-link {
    color: #FFFFFF !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    @media screen and (max-width: 1024px) {
      .c-worksList__list .c-link {
        color: #43B7FA !important; } }

.c-worksList__item {
  width: 50%;
  margin-bottom: 60px; }
  @media screen and (max-width: 700px) {
    .c-worksList__item {
      width: 100%;
      margin-bottom: 40px; } }
  .c-worksList__item > a {
    position: relative;
    display: block;
    padding: 0; }
    @media screen and (max-width: 700px) {
      .c-worksList__item > a {
        padding: 0; } }
    @media screen and (max-width: 1024px) {
      .c-worksList__item > a {
        padding-bottom: 50px; } }
    .c-worksList__item > a:hover .c-worksList__img-inner {
      left: 0; }

.c-worksList__img {
  width: 100%;
  padding-top: 45.81818%;
  position: relative;
  background-position: center;
  background-size: contain;
  overflow: hidden; }
  @media screen and (max-width: 1024px) {
    .c-worksList__img {
      overflow: visible; } }
  @media screen and (max-width: 768px) {
    .c-worksList__img {
      padding-top: 60.70529%;
      background-size: cover; } }
  @media screen and (max-width: 568px) {
    .c-worksList__img {
      padding-top: 71.9403%; } }
  .c-worksList__img-inner {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: -100%;
    transition: all 0.2s ease; }
    @media screen and (max-width: 1024px) {
      .c-worksList__img-inner {
        top: auto;
        left: 0;
        transform: none;
        height: auto;
        bottom: -25px; } }
    .c-worksList__img-inner:before {
      content: '';
      width: 100%;
      height: 100%;
      background-color: #098DB0;
      opacity: .7;
      display: block;
      position: absolute;
      top: 0;
      left: 0; }
      @media screen and (max-width: 1024px) {
        .c-worksList__img-inner:before {
          display: none; } }
  .c-worksList__img-wrap {
    position: relative; }
    .c-worksList__img-wrap:after {
      content: '';
      background-color: #43B7FA;
      width: 1px;
      height: 20px;
      display: block;
      position: absolute;
      top: -20px;
      left: 50%;
      transform: translateX(-50%); }

.c-worksList__title {
  color: #43B7FA;
  text-align: center;
  margin-bottom: 25px;
  position: relative; }

.c-worksList__desc {
  text-align: center;
  color: #333333;
  margin-top: -20px; }

#top {
  margin-top: 0; }
  #top.is-animate .l-header {
    top: calc(-1 * 80px);
    opacity: 0; }
    @media screen and (max-width: 1024px) {
      #top.is-animate .l-header__inner {
        top: calc(-1 * 70px);
        animation-name: headerDown-sp;
        animation-duration: .5s;
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;
        animation-delay: 2.3s; } }
  #top .p-mainVisual {
    width: 100%;
    height: 100vh; }
    #top .p-mainVisual__inner {
      height: 100%;
      display: flex;
      overflow: hidden; }
    #top .p-mainVisual__img {
      width: 50%;
      height: 100%;
      position: relative;
      opacity: 0; }
      #top .p-mainVisual__img:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        opacity: 0; }
      #top .p-mainVisual__img--left {
        background: url("/inc/image/top/pic_mainImg-left.jpg") no-repeat center/cover; }
        #top .p-mainVisual__img--left:before {
          left: -100%;
          background: url("/inc/image/common/animation/img_glass-left.png") no-repeat center right/cover; }
          @media screen and (max-width: 1024px) {
            #top .p-mainVisual__img--left:before {
              background-image: url("/inc/image/common/animation/img_glass-left-sp.png"); } }
      #top .p-mainVisual__img--right {
        background: url("/inc/image/top/pic_mainImg-right.jpg") no-repeat center/cover; }
        #top .p-mainVisual__img--right:before {
          right: -100%;
          background: url("/inc/image/common/animation/img_glass-right.png") no-repeat center left/cover; }
          @media screen and (max-width: 1024px) {
            #top .p-mainVisual__img--right:before {
              background-image: url("/inc/image/common/animation/img_glass-right-sp.png"); } }
  #top #wrapper {
    padding: 0; }
    @media screen and (max-width: 1130px) {
      #top #wrapper {
        padding-top: 60px; } }
  #top .p-news__wrap {
    display: flex;
    align-items: center;
    margin: 0 -30px;
    position: relative; }
    @media screen and (max-width: 1024px) {
      #top .p-news__wrap {
        flex-direction: column;
        align-items: flex-start;
        margin: 0; } }
  #top .p-news__block {
    padding: 0 30px; }
    @media screen and (max-width: 1024px) {
      #top .p-news__block {
        display: block;
        padding: 0;
        flex: 0 0 100% !important;
        width: 100%; } }
    #top .p-news__block--left {
      flex: 1 1 192.5px; }
      @media screen and (max-width: 1024px) {
        #top .p-news__block--left {
          position: relative;
          margin-bottom: 30px; } }
    #top .p-news__block--right {
      flex: 1 1 846.6px; }
  #top .p-news__link {
    text-align: center; }
    @media screen and (max-width: 1024px) {
      #top .p-news__link {
        position: absolute;
        bottom: 0;
        right: 0; } }
  #top .p-news .c-ttl2 {
    margin-bottom: 15px; }
    @media screen and (max-width: 1130px) {
      #top .p-news .c-ttl2 {
        font-size: 30px; } }
    @media screen and (max-width: 1024px) {
      #top .p-news .c-ttl2 {
        margin-bottom: 0;
        text-align: left; } }
  @media screen and (max-width: 1130px) {
    #top .p-news .c-link {
      font-size: 12px; } }
  @media screen and (max-width: 1024px) {
    #top .p-news .c-link {
      font-size: 1em; } }
  #top .p-news .c-newslist__item {
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #098DB0; }
    #top .p-news .c-newslist__item:last-child {
      border-bottom: none; }
  #top .p-service {
    overflow: hidden; }
    #top .p-service__list {
      display: flex;
      justify-content: space-between;
      margin: 0 -22px 72px; }
      @media screen and (max-width: 700px) {
        #top .p-service__list {
          margin: 0 -15px 50px; } }
    #top .p-service__item {
      position: relative;
      padding: 0 22px; }
      @media screen and (max-width: 700px) {
        #top .p-service__item {
          padding: 0; } }
      #top .p-service__item > a {
        display: block; }
        #top .p-service__item > a:focus {
          outline: none; }
    #top .p-service__img {
      max-width: 398px;
      margin: 0 auto 20px;
      position: relative; }
      @media screen and (max-width: 700px) {
        #top .p-service__img {
          max-width: 100%;
          padding: 0 10px; } }
      #top .p-service__img:after {
        content: '';
        background-color: #43B7FA;
        width: 1px;
        height: 20px;
        display: block;
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%); }
      #top .p-service__img img {
        display: block;
        width: 100%; }
    #top .p-service__title {
      font-size: 18px;
      color: #43B7FA;
      margin: 0;
      text-align: center; }
    #top .p-service__desc {
      position: relative; }
      @media screen and (max-width: 1130px) {
        #top .p-service__desc {
          text-align: center; } }
      #top .p-service__desc-txt {
        max-width: 687px;
        margin-bottom: 0; }
        @media screen and (max-width: 1130px) {
          #top .p-service__desc-txt {
            text-align: left;
            margin-bottom: 20px; } }
    #top .p-service .c-btn {
      position: absolute;
      bottom: 0;
      right: 0; }
      @media screen and (max-width: 1130px) {
        #top .p-service .c-btn {
          position: static; } }
    #top .p-service .slick-list {
      overflow: visible; }
      @media screen and (max-width: 700px) {
        #top .p-service .slick-list {
          overflow: hidden; } }
    #top .p-service .slick-slide {
      position: relative; }
      @media screen and (max-width: 700px) {
        #top .p-service .slick-slide {
          top: 0 !important; } }
      #top .p-service .slick-slide:nth-of-type(2) {
        top: 36px; }
      #top .p-service .slick-slide:nth-of-type(3) {
        top: 72px; }
  #top .p-strength {
    background: linear-gradient(transparent 20px, #DCF2FE 20px);
    margin-bottom: 40px; }
    @media screen and (max-width: 1130px) {
      #top .p-strength {
        margin-bottom: 100px; } }
    #top .p-strength__list {
      position: relative;
      left: -24px;
      margin-bottom: 0; }
      @media screen and (max-width: 1280px) {
        #top .p-strength__list {
          left: 0; } }
    #top .p-strength__item {
      width: 100%;
      padding-top: 56.875%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover; }
    #top .p-strength__content {
      position: relative;
      top: 60px; }
      @media screen and (max-width: 1024px) {
        #top .p-strength__content {
          padding-bottom: 150px; } }
      @media screen and (max-width: 768px) {
        #top .p-strength__content {
          padding-bottom: 0; } }
    #top .p-strength__btn {
      position: absolute;
      bottom: -10px;
      right: 48px; }
      @media screen and (max-width: 1024px) {
        #top .p-strength__btn {
          bottom: 50px; } }
      @media screen and (max-width: 768px) {
        #top .p-strength__btn {
          position: static;
          text-align: center; } }
    #top .p-strength .l-section {
      padding-top: 0;
      position: relative; }
    @media screen and (max-width: 768px) {
      #top .p-strength .grid {
        margin-bottom: 60px; } }
    #top .p-strength .slick-prev {
      left: -10px; }
    #top .p-strength .slick-next {
      right: -10px; }
  #top .p-works .c-heading {
    text-align: center;
    margin-bottom: 62px; }
    @media screen and (max-width: 700px) {
      #top .p-works .c-heading {
        text-align: left;
        margin-bottom: 55px; } }
    @media screen and (max-width: 700px) {
      #top .p-works .c-heading .c-heading__main br {
        display: none; } }
  @media screen and (max-width: 700px) {
    #top .p-infection {
      padding-left: 0;
      padding-right: 0; } }
  #top .p-infection__inner {
    width: 100%;
    height: 556px;
    display: flex; }
    @media screen and (max-width: 700px) {
      #top .p-infection__inner {
        height: 770px;
        flex-direction: column; } }
  #top .p-infection__bg {
    width: 50%;
    height: 100%;
    position: relative; }
    @media screen and (max-width: 700px) {
      #top .p-infection__bg {
        width: 100%; } }
    #top .p-infection__bg--left {
      background: url("/inc/image/top/pic_infection-left.jpg") no-repeat center/cover; }
      @media screen and (max-width: 700px) {
        #top .p-infection__bg--left {
          height: 46%; } }
    #top .p-infection__bg--right {
      background: url("/inc/image/top/pic_infection-right.jpg") no-repeat center/cover; }
      @media screen and (max-width: 700px) {
        #top .p-infection__bg--right {
          height: 54%; } }
      #top .p-infection__bg--right:before {
        content: '';
        background-color: #43B7FA;
        opacity: .9;
        display: block;
        width: 100%;
        height: 100%; }
  #top .p-infection__detail {
    width: calc(100% - 110px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    @media screen and (max-width: 1024px) {
      #top .p-infection__detail {
        width: 90%; } }
  #top .p-infection__heading {
    color: #FFFFFF;
    margin-bottom: 25px;
    font-size: 36px; }
    @media screen and (max-width: 768px) {
      #top .p-infection__heading {
        font-size: 30px; } }
    @media screen and (max-width: 700px) {
      #top .p-infection__heading {
        margin-bottom: 15px; } }
  #top .p-infection__desc {
    color: #FFFFFF;
    margin-bottom: 57px; }
    @media screen and (max-width: 700px) {
      #top .p-infection__desc {
        margin-bottom: 30px; } }
  #top .p-infection__btn {
    text-align: center;
    max-width: 290px;
    margin: 0 auto; }
    @media screen and (max-width: 700px) {
      #top .p-infection__btn {
        max-width: 100%; } }
    #top .p-infection__btn .c-btn {
      width: 100%; }
      #top .p-infection__btn .c-btn:before {
        color: #43B7FA; }
      #top .p-infection__btn .c-btn > span {
        width: 100%;
        color: #43B7FA;
        background-color: #FFFFFF; }
        #top .p-infection__btn .c-btn > span:before, #top .p-infection__btn .c-btn > span:after {
          display: none; }
